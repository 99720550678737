.inl-mis-color {
    color: #D20000;
}

.inl-amsg-bottom {
    margin-bottom: 10px;
}

.inl-pg-size {
    height: 30px;
    width: 70px;
}

.inl-grid-opt {
    height: 490px;
    text-align: center;
    width: 100%;
    overflow-x: hidden;
}

.inl-modal-width {
    width: 820px;
}

.inl-modal-padding {
    padding-top: 10px;
}

.inl-modal-col {
    margin-top: 1%;
}

.inl-modal-colp {
    margin-top: 1%;
    bottom: 7px;
}

.inl-modal-cola {
    text-align: right;
    bottom: 7px;
}

.sel-pointer {
    cursor: pointer;
}

.inl-modal-widows {
    widows: 500px;
}

.inl-modal-gt {
    text-align: left;
    bottom: 30px;
}

.inl-modal-lt {
    text-align: left;
    bottom: 7px;
}

.alertErr {
    background: #ab010133 0% 0% no-repeat padding-box;
    border: 1px solid #ab010199;
    opacity: 1;
    /* height: 40px; */
    text-align: left;
    font: normal normal medium 13px/18px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #ab0101;
}

.alertCircle {
    margin-top: -3px;
}

.alertClose {
    margin-top: -8px;
}

.alert-success {
    background: #25AB0133 0% 0% no-repeat padding-box;
    border: 1px solid #25AB0199;
    color: #25AB01;
    opacity: 1;
    height: 40px;
    text-align: left;
    letter-spacing: 0px;
    position: relative;
    padding: 9px 20px;
    font-size: 13px;
    font-family: 'Roboto';
}

.alert-failure {
    /* background-color: rgb(243, 169, 169);
  border: red;
  color: black;
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 15px;
  display: inline-block; */
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: 9px 20px;
    border: 1px solid transparent;
    border-color: #f5c6cb;
    font-size: 13px;
    font-family: 'Roboto';
    opacity: 1;
    height: 40px;
    text-align: left;
    letter-spacing: 0px;
}

.alert-close {
    margin-left: 1vw;
    color: darkgreen;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.alert-close:hover {
    color: black;
}

.alert-close-red {
    margin-left: 1vw;
    color: #721c24;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.alert-close-red:hover {
    color: black;
}
       
.alert{
    border-radius: 0px;
    border: 1px solid transparent;
    padding: 3px !important ;
    position:relative;

   }
   
.alert-danger{
     color: black !important;
     background-color: #f8d7da;
     border-color: #f5c6cb;
     font-size:13px;
     font-family:'Roboto';
     opacity: 1;
     text-align: left;
     letter-spacing: 0px;
}