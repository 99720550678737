 
  .fontbold
  {
    font-weight: 500;
  }
  
  
  .card-item-align
  {
    padding-left: 25px;
  }
  
  
