
.form-control {
    font-size: 13px!important;
    font-weight: light;
    color: black!important;
    border: 1px solid rgb(151, 150, 150)!important;
    padding: 5px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
    border: 1px solid #D7DCE1!important;
    border-radius: 2px;
    box-shadow: inset 0px 0px 3px #00000029!important;
    opacity: 1!important;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
  
    outline: 0;
    
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef!important;
    opacity: 1;
}

.submitbtn {
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    font: 13px;
    width: 70px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.canclbtn {
    background-color: #FFFFFF;
    height: 38px;
    width: 80px;
    margin-bottom: 20px;
    text-align: center;
    font: 13px;
    width: 70px;
    text-transform: uppercase;
    color: #676A6E;
    border-radius: 0;
    border: 1px solid #C1C8D0;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.btn2 {
    padding-left: 15px;
    margin-bottom: 15px;
}

.alert {
    background: #25AB0133 0% 0% no-repeat padding-box;
    border: 1px solid #25AB0199;
    opacity: 1;
    /* height: 40px; */
    text-align: left;
    font: normal normal medium 13px/18px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #25AB01;
}


.cancelLink {
    text-decoration: none;
    color: var(--unnamed-color-2688da);
    text-align: left;
    font: normal normal bold 15px/39px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #2688DA;
    opacity: 1;
}

.updatebtn {
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    font: 13px;
    width: 150px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}


.title {
    font: normal normal normal 24px/30px Roboto,sans-serif;
    color: rgb(22, 23, 24, 1);
    height: 32px;
    max-width: max-content;
    padding-bottom: 20px;
}
.second_header {
    padding-bottom: 10px;
}

.second_text {
    margin-right: 20px;
    font-size: 11px;
}

.closeButton {
    width: 100px;
    height: 38px;
    display: inline;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E9F0F3 100%) 0% 0% no-repeat padding-box;
    border: 1.4px solid lightgray;
    opacity: 1;
    color: #676A6E;
    border-radius: 0px;
    text-align: center;
    
    letter-spacing: 0px;
    text-transform: uppercase;
    /* display: block; */
    font: normal normal normal 13px/13px Roboto;
    padding: 11px 18px;
    float: right;
    align-content: center;
    margin: -7px;
}

.addButton {
    float: right;
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    width: 190px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    margin-top: -5px;
    cursor: pointer;
}
.addButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.second_header {
    padding-bottom: 10px;
}

.second_head {
    position: absolute;
    margin-left: -163px;
    font-size: 12px;
    font-weight: 500;
}

.modal {
    left: 10% !important;
    width: 80% !important;
}

#exampleModalLongTitle {
    text-align: left;
    font: normal normal normal 24px/39px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #161718;
    opacity: 1;
}
.d-flex {
    display: flex!important;
    align-items: baseline !important;
}

.modal-footer {
    height: 60px;
    background: #E4ECF0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000014;
    border: 1px solid #BCD1E0;
    opacity: 1;
    /* padding-bottom: 5px; */
}
.form-control {
    font-size: 13px!important;
    font-weight: light;
    color: black!important;
    
    padding: 5px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
    border: 1px solid #D7DCE1!important;
    border-radius: 2px;
    box-shadow: inset 0px 0px 3px #00000029!important;
    opacity: 1!important;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
  
    outline: 0;
    
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef!important;
    opacity: 1;
}

.submitbtn {
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    font: 13px;
    width: 70px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.canclbtn {
    background-color: #FFFFFF;
    height: 38px;
    
    margin-bottom: 20px;
    text-align: center;
    font: 13px;
    width: 70px;
    text-transform: uppercase;
    color: #676A6E;
    border-radius: 0;
    border: 1px solid #C1C8D0;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.btn2 {
    padding-left: 15px;
    margin-bottom: 15px;
}

.alert {
    background: #25AB0133 0% 0% no-repeat padding-box;
    border: 1px solid #25AB0199;
    opacity: 1;
    /* height: 40px; */
    text-align: left;
    font: normal normal medium 13px/18px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #25AB01;
}

.modal-body td {
    border: none;
}

.modal-body th {
    border: none;
    
    text-align: left;
    font: normal normal normal 13px/39px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #676A6E;
    opacity: 1;
}

.cancelLink {
    text-decoration: none;
    
    text-align: left;
    font: normal normal bold 15px/39px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #2688DA;
    opacity: 1;
}

.updatebtn {
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    font: 13px;
    width: 150px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.modal-body {
    text-align: left;
    font: normal normal normal 13px/20px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #161718;
    opacity: 1;
    /* padding: 0; */
}

.title {
    font: normal normal normal 24px/30px Roboto,sans-serif;
    color: rgb(22, 23, 24, 1);
    height: 32px;
    max-width: max-content;
    padding-bottom: 20px;
}

.modal {
    left: 10% !important;
    width: 80% !important;
}

.modal-body {
    text-align: left;
    font: normal normal normal 13px/20px Roboto,sans-serif;
    letter-spacing: 0px;
    
        max-height: calc(100vh - 200px);
        overflow-y: auto;
   
    
    
    color: #161718;
    opacity: 1;
    /* padding: 0; */
}

.modal-body input {
    height: 34px;
    width: 200px;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
    opacity: 1;
}

.modal-header {
    background: #E4ECF0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000029;
    opacity: 1;
    height: 72px;
    padding-bottom: 0;
    text-align: center;
}

.second_header {
    padding-bottom: 10px;
}

.inl-modal-col {
    margin-top: 1%;
}

.inl-modal-cola {
    text-align: right;
    bottom: 7px;
}

.second_text {
    margin-right: 20px;
    font-size: 11px;
}

.inl-modal-width {
    width: 820px;
}

.closeButton {
    width: 100px;
    height: 38px;
    display: inline;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E9F0F3 100%) 0% 0% no-repeat padding-box;
    border: 1.4px solid lightgray;
    opacity: 1;
    color: #676A6E;
    border-radius: 0px;
    text-align: center;
    
    letter-spacing: 0px;
    text-transform: uppercase;
    /* display: block; */
    font: normal normal normal 13px/13px Roboto,sans-serif;
    padding: 11px 18px;
    float: right;
    align-content: center;
    margin: -7px;
}

.addButton {
    float: right;
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    width: 190px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    margin-top: -5px;
    cursor: pointer;
}
.addButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.modal-header{
    background-color: white;
    justify-content: inherit
  }

#exampleModalShortTitle {
    text-align: left;
    font: normal normal normal 18px/20px Roboto,sans-serif;
    font-weight: 500;
    letter-spacing: 0px;
    color: #161718;
    opacity: 1;
}

.inl-modal-widows {
    widows: 500px;
}

.inl-modal-gt {
    text-align: left;
    bottom: 30px;
}

.inl-modal-lt {
    text-align: left;
    bottom: 7px;
}

.inl-modal-padding {
    padding-top: 10px;
}

.second_header {
    padding-bottom: 10px;
}

.inl-modal-colp {
    margin-top: 1%;
    bottom: 7px;
}

.second_head {
    position: absolute;
    margin-left: -163px;
    font-size: 12px;
    font-weight: 500;
}


.modal-body input {
    border: 1px solid #D7DCE1 !important;
    border-radius: 2px;
    opacity: 1;
}