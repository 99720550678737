/* You can add global styles to this file, and also import other style files */


/* @import"../node_modules/@angular/material/prebuilt-themes/indigo-pink.css"; */


/* html, body { } */

@import './assets/alert.scss';
@import './assets/modal.scss';
@import './assets/font-style.scss';
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.multiselect .multiselect-dropdown {
    width: 14% !important;
    height: 35px  !important;
    max-width: 2000px !important;
    font-size: 13px !important;
    font-weight: light !important;
    color: grey !important;
    display: inline-block;
    text-align: left !important;
    margin-left: 1em;
    margin-right: 1em;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 2px !important;
    box-shadow: inset 0px 0px 3px #00000029;
 }
.page-drpdwn-label {
    text-align: left;
    font-weight: 600;
    font-stretch: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}


/* added common css for ag-grid tables */

.ag-header-group-cell-label {
    flex-direction: column;
    margin-top: 5px;
}


/* ::ng-deep .ag-header-cell-label { flex-direction: column; margin-top: 5px;} */

.ag-header-row-column-group {
    background-color: #E4ECF0;
    font: normal normal medium 16px Roboto,sans-serif;
    color: #161718;
}

.ag-header-row-column {
    background-color: #E4ECF0;
    font: normal normal medium 16px Roboto,sans-serif;
    color: #161718;
}

.ag-header-row-floating-filter {
    background-color: #F4F0DF;
    height: 50px;
}

.ag-icon-filter {
    display: none;
}

.ag-icon-menu {
    display: none;
}

.ag-theme-balham .ag-row-odd {
    background-color: #F7F7F7;
}

.ag-row {
    text-align: left;
}

.ag-header-cell-text {
    color: var(--unnamed-color-676a6e);
    font: normal normal medium 13px/16px Roboto,sans-serif;
    color: #676A6E;
    text-transform: uppercase;
    opacity: 1;
}

.ag-header-group-text {
    color: var(--unnamed-color-676a6e);
    font: normal normal medium 13px/16px Roboto,sans-serif;
    color: #676A6E;
    text-transform: uppercase;
    opacity: 1;
}

#link {
    text-decoration: underline !important;
}

a {
    color: blue;
    cursor: pointer;
}

.ag-theme-balham .ag-row {
    height: 35px;
}

.ag-theme-balham .ag-cell {
    line-height: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ag-theme-balham .ag-header-icon {
    margin-left: 3px;
}

.ag-theme-balham .ag-checkbox-input-wrapper input {
    cursor: pointer;
}

#blockColorblindContent {
    display: none !important;
}

.ag-header-cell-label {
    justify-content: center !important;
}

.ag-theme-balham .ag-ltr .ag-cell {
    text-align: center;
}

.ag-cell-focus,
.ag-cell-no-focus {
    border: none !important;
}

.no-border.ag-cell:focus {
    border: none !important;
    outline: none;
}

.ag-theme-balham .ag-row-selected {
    background-color: transparent;
}

.ag-floating-filter-full-body {
    padding-right: 20px;
}


/* adding for Power-Bi report link */

.downloadLink {
  
    text-align: left;
    font: normal normal medium 20px/39px Roboto,sans-serif;
    letter-spacing: 0px;
    color: #2688DA;
    opacity: 1;
}

.ag-header-cell::after {
    height: 0% !important
}

.textfield {
    font-size: 13px;
    font-weight: light;
    color: black;
    border: 1px solid rgb(151, 150, 150);
    padding: 5px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
    box-shadow: inset 0px 0px 3px #00000029;
    opacity: 1;
}

.textfield:disabled {
    background: lightgrey !important;
}

.required ::after {
    /* display: inline-block; */
    content: "*";
    color: red;
    margin-left: 1px
}

.btn-dark-toyota {
    color: #fff;
    border-color: #343a40;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.btn-black {
    background: #161718 0% 0% no-repeat padding-box;
}

.addButton1 {
    float: right;
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    width: 90px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    margin-top: -5px;
    margin-left: 33rem;
}

.cancelButton1 {
    float: left;
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    width: 90px;
    padding-top: 10px;
    text-transform: uppercase;
    color: royalblue;
    opacity: 0.8;
    font-weight: 500;
    border-radius: 0;
    margin-top: -5px;
    left: 4rem;
    margin-left: 1rem;
}

.addButton1:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

#btn_cancel_nav {
    float: left;
    color: royalblue
}

#btn_save_nav {
    float: right;
}

.btn_nav {
    text-align: center;
    font: normal normal 13px/18px Roboto,sans-serif;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    border-radius: 0px;
    min-height: 35px;
    /* width: 210px; */
    color: #FFFFFF;
    background: #161718 0% 0% no-repeat padding-box;
    margin-right: 10px;
}

#btn_save_nav[disabled] {
    background: transparent linear-gradient(180deg, grey 0%, grey 100%) 0% 0% no-repeat padding-box;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: lightgrey !important;
    opacity: 1;
    /* Firefox */
}

 :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: lightgrey !important;
}

 ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: lightgrey !important;
}

input:focus,
.form-control:focus {
    outline: none !important;
    border-color: lightgrey !important;
    box-shadow: none;
}

input:focus,
textarea:focus,
.form-control:focus {
    outline: none !important;
    border-color: none;
    box-shadow: none;
}

.required::after {
    content: " *";
    color: red;
  }

  .footer-link{
    position: relative; top: 15px;
   width: 444px; color: blue;height: 18px;text-align: right;
   font: normal normal 13px/16px Roboto ,sans-serif;
   letter-spacing: 0px;
   opacity: 1;
   }
   .spacer{
     width:100%; 
   }
   .footer{
     top: 751px ;
     left: 0px;
     width:100%;
     height: 48px;
     background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
     box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.39);
     opacity: 1;
     }
     
     .copyright{
     position: relative;
     top: 15px;
     width: 117px;
     height: 18px;
     text-align: left;
     font: normal 13px/16px Roboto,sans-serif;
     letter-spacing: 0px;
     color: rgba(102, 106, 110, 1);
     opacity: 1;
       }

       
.alert{
  border-radius: 0px;
  border: 1px solid transparent;
  padding: 2px;
  position:relative;
  margin-bottom: 1rem;
  
 }
 
 .alert-danger{
   color: black !important;
   background-color: #f8d7da;
   border-color: #f5c6cb;
   font-size:13px;
   font-family:'Roboto',sans-serif;
   opacity: 1;
   text-align: left;
   letter-spacing: 0px;
 }

 input[type="text" i],input[type="email" i],input[type="number" i],input[type="password" i] ,select,input[type="date" i]{
    height: 25px ;
    border: 1px solid #D7DCE1 !important;
    border-radius: 2px  !important;
    opacity: 1 !important;
   
}

input[readonly]{
    border-color: darkgrey; 			  			   		
    background:#d3d3d3!important;
    color: black;
    /* padding: 2px 0px; */  			
}
input[type="radio" i]{
    color:initial;
    background-color: initial;
}

@media screen and (min-width: 2500px){
    h2{
        font-size: calc(2.5vh) !important; 
      }
      input,select ,span,.buttonPSLogin,label{
        min-height: calc(2vh) !important;
      }
      .btn-footer,.buttonPSLogin{
        font-size: calc(1.5vh) !important;
      }
      .buttonPSLogin{
          padding: 0px;
          min-height:55px !important;
         
      }
}
.btn-footer{
    font-size: 10px !important ;
}

.buttonPSLogin {
    margin:5px 3px 0 3px;
    padding:5px;
    min-width: 12%;
    text-decoration: none;
    text-align: center;
    font: bold 20px Roboto, sans-serif;  
    text-transform: uppercase;
    color:#FFF;
    outline-style:none;
    background-color:   #971518;
    border: 1px solid #971518;
    border-radius: 5px; 
    font-size: medium;
  }

  
  
:host ::ng-deep .multiselect-dropdown .dropdown-btn {
    width: 200px !important;
    height: 35px !important;
    /* height: 30px !important; */
    padding: 4px 8px 4px 8px!important;
    ;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 0px!important;
    box-shadow: inset 0px 0px 3px #00000029;
    opacity: 1;
}

.searchRowGrid ::ng-deep .multiselect-dropdown .dropdown-btn .selected-item {
   // border: 1px solid #337ab7;
    margin-right: 3px;
    background: #337ab7;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left;
    display: flex;
    max-width: 180px!important;
}

::ng-deep .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    width: 22px!important;
    height: 12px!important;
}

:host ::ng-deep .multiselect-dropdown .dropdown-list {
    position: fixed;
    padding-top: 6px;
    width: 200px;
    z-index: 9999;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    margin-top: 0px;
    box-shadow: 0 1px 5px #959595;
}

::ng-deep .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before {
    border-style: none!important;
    border-width: 0!important;
    border-color: #fff transparent!important;
    content: "\f078"!important;
    font-size: .7rem;
   
}

::ng-deep .multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
    margin-top: 18px!important;
}

.dropdown1 {
    margin: 0%;
    /* display: inline-block; */
    /* border: 1px solid #adadad; */
    /* width: 180px; */
    width: 100%;
    // height: 30px;
    padding: 0px 0px;
    /* margin-bottom: 0; */
    font-weight: lighter;
    border-color: #adadad;
    border-radius: 2px  !important;
    box-shadow: inset 0px 0px 3px #00000029 !important;
    opacity: 9999 !important;
    /* line-height: 1.52857143; */
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #D7DCE1 !important;
    color: rgba(103, 106, 110, .4) !important
}
.multiselect-dropdown .dropdown-btn ,.multiselect-dropdown[_ngcontent-hyb-c130] .dropdown-btn[_ngcontent-hyb-c130]
{
   // display: initial !important;
    border: none !important;
    border-radius: 2px  !important;
    opacity: 1 !important;
    padding:0px !important;
}


.selected-item{
    margin-left: 8px !important;
    margin-top: 2px !important;
}